// import './App.css';
import { useState, useEffect } from 'react';

import Round from './Round.js';

function Game ({ names, nameCount, onPick, startNewGame }) {

  const [rounds, setRounds] = useState([]);

  const [round, setRound] = useState([]);

  const [index, setIndex] = useState(0);

  useEffect(() => {

    const filtered = names.filter((name) => !name.hidden);

    const userNames = filtered.slice(0, nameCount);

    const shuffled = shuffle([...userNames]);

    setRounds(shuffled);

  }, [names, nameCount]);

  useEffect(() => {

    if (!rounds || !rounds.length) {
      return;
    }

    const next = index + 1;

    setRound([
      rounds[index],
      rounds[next]
    ]);

  }, [rounds, index]);

  function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
  }

  const handlePick = (event) => {

    event.preventDefault();

    let skip = 2;

    // if (!event.target.value) {
    //   skip = 2;
    // }

    let data = round.reduce(function(accumulator, current) {
      let choice = {
        ...current
      };

      choice.views++;

      let picked = current.name === event.target.value;

      if (picked) {
        choice.votes++;
      }

      accumulator.push(choice);

      return accumulator;

    }, []);

    onPick({ data });

    const next = index + skip;

    if (next > rounds.length -2) {
      startNewGame();
      return;
    }

    setIndex(next);
  };

  return (<Round round={round} onPick={handlePick}/>);
}

export default Game;
