// import './App.css';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import Button from '@mui/material/Button';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useEffect, useState } from 'react';

function PickNamesDialog ({ open, setOpen, list, onPickNames }) {
  const [names, setNames] = useState([]);

  useEffect(() => {
    // const names = list.reduce((carry, item) => {
    //   carry[item.name] = !!item.hidden;
    //   return carry;
    // }, {});

    // setHidden(names);

    const sorted = list.map((item) => {
      return {
        name: item.name,
        hidden: !!item.hidden
      };
    }).sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });

    const show = sorted.filter((name) => !name.hidden);

    const hide = sorted.filter((name) => name.hidden);

    setNames([
      ...show,
      ...hide
    ]);

  }, [list]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const hiddenNames = list.reduce((carry, name) => {
      carry[name.name] = !!name.hidden;
      return carry;
    }, {});

    const hiddenUpdates = names.reduce((carry, name) => {
      carry[name.name] = !!name.hidden;
      return carry;
    }, {});

    const changedNames = Object.keys(hiddenNames).filter((name) => {
      return hiddenNames[name] !== hiddenUpdates[name];
    });

    const save = changedNames.reduce((carry, name) => {
      carry[name] = {
        hidden: hiddenUpdates[name]
      };
      return carry;
    }, {});

    onPickNames(save);

    setOpen(false);
  };

  const onChange = (event) => {
    let newNames = names.map((name) => {
      if (name.name === event.target.value) {
        name.hidden = !event.target.checked;
      }
      return name;
    });

    setNames(newNames);
  };

  return (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {"Pick Names"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {names.map(function(item) {
          return(<FormGroup key={item.name}>
              <FormControlLabel control={<Checkbox checked={!item.hidden} value={item.name} onChange={onChange}/>} label={item.name} size="small"/>
            </FormGroup>);
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>);
}

export default PickNamesDialog;
