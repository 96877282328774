// import './App.css';


import { Box, Grid, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import Button from '@mui/material/Button';

import ListDialog from './ListDialog.js';

import PickNamesDialog from './PickNamesDialog.js';

import { useState } from 'react';

function Lobby ({ onPlay, names, onPickNames }) {

  const [open, setOpen] = useState(false);

  const [listOpen, setListOpen] = useState(false);

  const [pickNamesOpen, setPickNamesOpen] = useState(false);

  // const handleClickOpenInstructions = () => {
  //   setOpen(true);
  // };

  const handleCloseInstructions = () => {
    setOpen(false);
  };

  const handleClickOpenList = () => {
    setListOpen(true);
  };

  const handleClickOpenPickNames = () => {
    setPickNamesOpen(true);
  };

  function handleSubmit (event) {
    const data = new FormData(event.currentTarget);
    onPlay({ nameCount: data.get('name-count') });
    event.preventDefault();
  }

  return (<Box
    component="form"
    onSubmit={handleSubmit}
    fullWidth
  >
    <Grid container spacing={1}>
     <Grid item xs={6}>
      <FormControl fullWidth sx={{ m: 1 }} size="small">
        <InputLabel id="name-count-label">Names</InputLabel>
        <Select
          defaultValue={1000}
          labelId="name-count-label"
          id="name-count-select"
          name="name-count"
          // value={nameCount}
          label="Top Names"
        >
          <MenuItem value={1000}>All</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={250}>250</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </FormControl>
     </Grid>
     <Grid item xs={6}>
      <FormControl fullWidth sx={{ m: 1 }}>
        <Button type="submit" fullWidth variant="contained">Play</Button>
      </FormControl>
     </Grid>
    </Grid>

    <FormControl fullWidth sx={{ m: 1 }}>
      <Button variant="text" onClick={handleClickOpenPickNames} sx={{ m: 1 }}>
        Pick Names
      </Button>
    </FormControl>

    <FormControl fullWidth sx={{ m: 1 }}>
      <Button variant="text" onClick={handleClickOpenList} sx={{ m: 1 }}>
        My Top Names
      </Button>
    </FormControl>

    {/* <FormControl fullWidth sx={{ m: 1 }}>
      <Button variant="text" onClick={handleClickOpenInstructions} sx={{ m: 1 }}>
        Instructions
      </Button> */}

    <a href="Untitled.mp4" target = "_blank">Demo</a>

    <PickNamesDialog open={pickNamesOpen} setOpen={setPickNamesOpen} list={names} onPickNames={onPickNames}/>

    <ListDialog open={listOpen} setOpen={setListOpen} list={names}/>

    <Dialog
      open={open}
      onClose={handleCloseInstructions}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Instructions"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Pick the number of names that you want compare. Start with 1000 and work down, playing at least a few rounds at each level. The number you pick will only show the top names, so if you pick 500, it will exclude the bottom 500.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseInstructions} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>

  </Box>);
}

export default Lobby;
