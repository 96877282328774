// import './App.css';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import Button from '@mui/material/Button';

import { useEffect, useState } from 'react';

function ListDialog ({ open, setOpen, list }) {

  const [names, setNames] = useState([]);

  useEffect(() => {

    const show = list.filter((name) => !name.hidden);

    setNames(show);

  }, [list]);

  const handleClose = () => {
    setOpen(false);
  };

  return (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {"Top Names"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <ol>
          {names.map(function(item) {
            return (<li key={item.name}>{item.name}</li>);
            })}
          </ol>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>);
}

export default ListDialog;
