import './App.css';
import { useState, useEffect, useCallback } from 'react';

import Lobby from './Lobby.js';

import Game from './Game.js';

import firebase from "./firebase"

import { Box, Button } from '@mui/material';

import { Grid } from '@mui/material';

var provider = new firebase.auth.GoogleAuthProvider();

const allNames = require('./boys.json').names;

function App () {
  const [user, setUser] = useState(undefined);

  const [scores, setScores] = useState({});

  const [nameCount, setNameCount] = useState(undefined);

  const [names, setNames] = useState([]);

  // useEffect(() => {
    firebase.auth()
        .onAuthStateChanged((user) => {
        setUser(user);

        if (user) {
          firebase
            .database()
            .ref(`users/${user.uid}/user`)
            .set({
              name: user.displayName,
              email: user.email,
            });
        }
      });
  // }, []);

  const handleLogin = function() {
    firebase.auth().signInWithRedirect(provider);
  };

  const handlePlay = function ({ nameCount: count }) {
    setNameCount(count);
  };

  const handlePick = function ({ data }) {
    data.forEach((save) => {
      firebase
        .database()
        .ref(`users/${user.uid}/scores/${save.name}`)
        .set({
          views: save.views,
          votes: save.votes
        });
    });
  };

  const handlePickNames = (updates) => {
    const save = Object.keys(updates).reduce((carry, name) => {
      carry[name] = {
        ...scores[name],
        hidden: updates[name].hidden
      };
      return carry;
    }, {});

    firebase
      .database()
      .ref(`users/${user.uid}/scores`)
      .update(save);

    const newScores = Object.keys(updates).reduce((carry, name) => {
      if (!(name in carry)) {
        carry[name] = {};
      }
      carry[name].hidden = updates[name].hidden;
      return carry;
    }, scores);

    setScores(newScores);
  };

  const startNewGame = function () {
    setNameCount(undefined);

    loadUserScores();
  };

  useEffect(() => {
    const namesWithScores = allNames.map((name) => {

      let score = scores[name] || {};

      score.views = score.views || 0;

      score.votes = score.votes || 0;

      score.hidden = score.hidden || false;

      return {
        name: name,
        ...score
      }
    });

    const namesWithRanks = namesWithScores.map((row) => {
      const rank = ((row.votes + 1.9208) / (row.votes + row.views) - 1.96 *
				Math.sqrt((row.votes * row.views) / (row.votes + row.views) + 0.9604) /
				(row.votes + row.views)) / (1 + 3.8416 / (row.votes + row.views));

      return {
        ...row,
        rank: rank ? rank : 0
      };
    }).sort((a, b) => {
      return a.rank - b.rank
    });

    namesWithRanks.reverse();

    setNames(namesWithRanks);

  }, [scores]);

  const loadUserScores = useCallback(() => {
      firebase
        .database()
        .ref(`users/${user.uid}/scores`)
        .once('value')
        .then((snapshot) => snapshot.val())
        .then(data => {
          if (!data) {
            setScores({});
          } else {
            setScores(data);
          }
        });
  }, [user]);

  useEffect(() => {
    if (!user) {
      setScores({});
      return;
    }

    loadUserScores();
  }, [user, loadUserScores]);

  const ss = {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '2em',
    letterSpacing: '2px',
    transform: 'rotate(5deg)'
  };

  return (
    <div className="App">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >

        <Grid item xs={8}>
          <Box sx={{ m: 1 }}>
            <span style={ss}>N</span>
            <span style={ss}>a</span>
            <span style={ss}>m</span>
            <span style={ss}>e</span>&nbsp;
            <span style={ss}>G</span>
            <span style={ss}>a</span>
            <span style={ss}>m</span>
            <span style={ss}>e</span>
          </Box>

          {!user && <Button fullWidth variant="contained" onClick={handleLogin} sx={{ m: 1 }}>Login with Google</Button>}

          {user && names && !nameCount && <Lobby onPlay={handlePlay} names={names} onPickNames={handlePickNames}/>}

          {user && nameCount && <Game names={names} nameCount={nameCount} onPick={handlePick} startNewGame={startNewGame}/>}
        </Grid>

      </Grid>
    </div>
  );
}

export default App;
