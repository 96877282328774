// import './App.css';

import { Box } from '@mui/material';

import Button from '@mui/material/Button';

function Round ({ round, onPick }) {
  return (<Box
    component="form"
    fullWidth
    // sx={{
    //   marginTop: 8,
    //   display: 'flex',
    //   flexDirection: 'column',
    //   alignItems: 'center',
    // }}
  >
    {round.map((name) => {
        return (<Button variant="contained" key={name.name} value={name.name} onClick={onPick} fullWidth sx={{ m: 1 }}>{name.name}</Button>);
    })}

    <Button key={null} value={null} onClick={onPick} fullWidth sx={{ m: 1 }}>Skip</Button>

  </Box>);
}

export default Round;
