import firebase from "firebase/app"
import "firebase/database"
import "firebase/auth"
const firebaseConfig = {
    apiKey: "AIzaSyBfdQam_lu0UFU1e6Lf-bvcKsgfqoGc4Io",
    authDomain: "nameapp.me",
    databaseURL: "https://test-11b75.firebaseio.com",
    projectId: "test-11b75",
    storageBucket: "test-11b75.appspot.com",
    messagingSenderId: "416469322491",
    appId: "1:416469322491:web:d280dbc56bd32d85aabe5e"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// firebase.analytics();
export default firebase
